import { pick, values, sortBy } from "lodash";
import { ICountry } from "../common/typings";

/* eslint-disable prettier/prettier */
interface IAirlineMap { [key: string]: ICountry; }
export const airlines: IAirlineMap = {
  "A3":{label:"Aegean Airlines", value:"A3"},
"SU":{label:"Aeroflot ", value:"SU"},
"AK":{label:"Air Asia", value:"AK"},
"BT":{label:"Air Baltic", value:"BT"},
"CA":{label:"Air China", value:"CA"},
"AF":{label:"Air France", value:"AF"},
"KM":{label:"Air Malta", value:"KM"},
"JU":{label:"Air Serbia", value:"JU"},
"AS":{label:"Alaska Airlines", value:"AS"},
"AA":{label:"American Airlines", value:"AA"},
"OS":{label:"Austrian Airlines", value:"OS"},
"0B":{label:"Blue Air", value:"0B"},
"BA":{label:"British Airways", value:"BA"},
"SN":{label:"Brussels Airlines", value:"SN"},
"FB":{label:"Bulgaria Air", value:"FB"},
"CP":{label:"Canadian Airlines", value:"CP"},
"CY":{label:"Cyprus Airways", value:"CY"},
"OK":{label:"Czech Airlines", value:"OK"},
"DL":{label:"Delta Airlines", value:"DL"},
"U2":{label:"Easy Jet", value:"U2"},
"WK":{label:"Edelweiss Air", value:"WK"},
"MS":{label:"Egyptair", value:"MS"},
"LY":{label:"El Al Israel Airlines", value:"LY"},
"EL":{label:"Ellinair", value:"EL"},
"EK":{label:"Emirates", value:"EK"},
"EY":{label:"Etihad", value:"EY"},
"EW":{label:"Eurowings", value:"EW"},
"AY":{label:"Finnair", value:"AY"},
"BE":{label:"Flybe", value:"BE"},
"GF":{label:"Gulf Air", value:"GF"},
"HR":{label:"Hahn Air", value:"HR"},
"IB":{label:"Iberia Airlines", value:"IB"},
"FI":{label:"IcelandAir", value:"FI"},
"9W":{label:"Jet Airways", value:"9W"},
"LS":{label:"Jet2.com", value:"LS"},
"KL":{label:"KLM", value:"KL"},
"NG":{label:"Lauda Air", value:"NG"},
"LH":{label:"Lufthansa", value:"LH"},
"MH":{label:"Malaysia Airlines", value:"MH"},
"DH":{label:"Norwegian Air", value:"DH"},
"OA":{label:"Olympic Air", value:"OA"},
"BL":{label:"Pacific Airlines", value:"BL"},
"QF":{label:"Qantas", value:"QF"},
"QR":{label:"Qatar", value:"QR"},
"FV":{label:"Rossiya", value:"FV"},
"RJ":{label:"Royal Jordanian", value:"RJ"},
"FR":{label:"Ryan Air", value:"FR"},
"S7":{label:"S7 Airlines", value:"S7"},
"TR":{label:"Scoot", value:"TR"},
"SQ":{label:"Sigapore Airlines", value:"SQ"},
"GQ":{label:"Sky Express", value:"GQ"},
"PQ":{label:"SkyUp", value:"PQ"},
"LX":{label:"Swiss International Airlines", value:"LX"},
"RO":{label:"Tarom", value:"RO"},
"MT":{label:"Thomas Cook Airlines", value:"MT"},
"HV":{label:"Transavia", value:"HV"},
"BY":{label:"TUI Airways", value:"BY"},
"U8":{label:"TUS Airways", value:"U8"},
"TK":{label:"Turkish Airlines", value:"TK"},
"PS":{label:"Ukraine International Airlines", value:"PS"},
"UA":{label:"United Airlines", value:"UA"},
"U6":{label:"Ural Airlines", value:"U6"},
"VN":{label:"Vietnam Airlines", value:"VN"},
"VS":{label:"Virgin Atlantic", value:"VS"},
"VA":{label:"Virgin Australia", value:"VA"},
"V7":{label:"Volotea", value:"V7"},
"VY":{label:"Vueling", value:"VY"},
"W6":{label:"Wizz Air", value:"W6"},
"BH": {label: "BH AIR", value: "BH"},
"LO": {label: "LOT", value: "LO"},
"PG": {label: "Bangkok Airways", value: "PG"},
"2L": {label: "Helvetic Airways", value: "2L"},
"TP": {label: "Tap Air Portugal", value: "TP"},
"SK": {label: "SAS Airlines", value: "SK"},
"AM": {label: "Aeromexico", value: "AM"},
"Y4": {label: "Volaris", value: "Y4"},
"QS": {label: "Smartwings", value: "QS"},
"A2": {label: "Animawings", value: "A2"},
}


export const airlinesOptions = sortBy(values(airlines), ['label']).map(airport => pick(airport, ['value', 'label']));





